export const FirebaseConfig = {
  apiKey: "AIzaSyAw3E_nBwQiEkImzKvpnnbB8gvpQgjEnt8",
  authDomain: "duck.helpinghands.community",
  databaseURL: "https://helping-hands-development.firebaseio.com",
  projectId: "helping-hands-development",
  storageBucket: "helping-hands-development.appspot.com",
  messagingSenderId: "1054204986784",
  appId: "1:1054204986784:web:4345bf0db17047afadd0c5",
  measurementId: "G-39VELQTV8V",
  stripePublishableKey: "pk_test_fLifUPjjs1ebfsBwGZl8gB7p00Zj6pz1xc",
};

// use this when running `locize command`
export const LocizeConfig = {
  projectId: "38c97c08-850c-4707-94b0-37015b180cd9",
  apiKey: "60e171c1-964b-41b7-87e8-8cd6c87f3623",
};

export const ErrorReportingConfig = {
  projectId: "helping-hands-development",
  apiKey: "AIzaSyCCnmgj-y6KNEIHkWbU5rkvGg6kd8HQXrE",
};
