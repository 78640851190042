export const FirebaseConfig = {
  apiKey: "AIzaSyBHdAnRWZeFrMbXbJON6y1k5qEIl2CMRLg",
  authDomain: "helpinghands.community",
  databaseURL: "https://helping-hands-community.firebaseio.com",
  projectId: "helping-hands-community",
  storageBucket: "helping-hands-community.appspot.com",
  messagingSenderId: "18585971349",
  appId: "1:18585971349:web:056b14e1c4eb48ea16c99c",
  measurementId: "G-M1KJHHWZPH",
  stripePublishableKey: "pk_live_seR2EqUlAr8FDDnTQx0TyXs0003dQbpZNF",
};

export const ErrorReportingConfig = {
  projectId: "helping-hands-community",
  apiKey: "AIzaSyDTHUEX9XY3Iivr_ksCfYA5o31WbV3JlOQ",
};
