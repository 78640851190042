import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route } from "react-router-dom";

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search },
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options,
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {
  // FOR TESTING IN DEV
  // const isGAEnabled = window.location.href.includes("localhost:3000");

  // FOR PROD
  const isGAEnabled = window.location.href.includes("helpinghands.community");

  if (isGAEnabled) {
    ReactGA.initialize("UA-161413614-1");
  }

  return isGAEnabled;
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {int} value
 */
const sendEvent = ({ category, action, label, value }) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    ...(value && { value: value }),
  });
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  sendEvent,
};
