export function stateInRegion(requestState, selectedRegion) {
  if (requestState !== "CA") {
    return REGION_MAP[selectedRegion].includes(requestState);
  }
  return false;
}

export function northOrSouthCA(requestState, requestZipcode, selectedRegion) {
  if (requestState === "CA") {
    if (selectedRegion === "CA-North") {
      if (
        parseInt(requestZipcode) > 93599 ||
        norCalZipCodes.has(requestZipcode)
      ) {
        return true;
      }
    } else {
      if (
        parseInt(requestZipcode) < 92328 ||
        soCalZipCodes.has(requestZipcode)
      ) {
        return true;
      }
    }
  }
  return false;
}

// regions defined by field-ops
export const REGION_MAP = {
  Pacific: ["AK", "HI", "OR", "WA"],
  Midwest: [
    "IA",
    "IL",
    "IN",
    "KS",
    "KY",
    "MI",
    "MN",
    "OH",
    "MO",
    "ND",
    "NE",
    "OK",
    "SD",
    "WI",
  ],
  Mountain: ["AZ", "CO", "ID", "MT", "NM", "NV", "UT", "WY"],
  Northeast: [
    "CT",
    "DC",
    "DE",
    "MA",
    "MD",
    "ME",
    "NH",
    "NJ",
    "NY",
    "PA",
    "RI",
    "VA",
    "VT",
    "WV",
  ],
  Southeast: ["AL", "AR", "FL", "GA", "MS", "NC", "LA", "SC", "TN"],
  Texas: ["TX"],
  Others: ["AS", "FM", "GU", "MH", "MP", "PW", "PR", "VI"],
};

// norcal & socal zipcodes defined by field-ops
// these zipcodes are defined explicitly because their ranges overlap
let norCalZipCodes = new Set([
  "92328",
  "92384",
  "92389",
  "93201",
  "93202",
  "93204",
  "93207",
  "93208",
  "93210",
  "93212",
  "93218",
  "93219",
  "93221",
  "93223",
  "93227",
  "93230",
  "93232",
  "93234",
  "93235",
  "93237",
  "93239",
  "93242",
  "93244",
  "93245",
  "93246",
  "93247",
  "93256",
  "93257",
  "93258",
  "93260",
  "93261",
  "93262",
  "93265",
  "93266",
  "93267",
  "93270",
  "93271",
  "93272",
  "93274",
  "93275",
  "93277",
  "93278",
  "93279",
  "93282",
  "93286",
  "93290",
  "93291",
  "93292",
  "93426",
  "93450",
  "93512",
  "93513",
  "93514",
  "93515",
  "93517",
  "93522",
  "93526",
  "93529",
  "93530",
  "93541",
  "93542",
  "93545",
  "93546",
  "93549",
]);

let soCalZipCodes = new Set([
  "92329",
  "92331",
  "92332",
  "92333",
  "92334",
  "92335",
  "92336",
  "92337",
  "92338",
  "92339",
  "92340",
  "92341",
  "92342",
  "92344",
  "92345",
  "92346",
  "92347",
  "92350",
  "92352",
  "92354",
  "92356",
  "92357",
  "92358",
  "92359",
  "92363",
  "92364",
  "92365",
  "92366",
  "92368",
  "92369",
  "92371",
  "92372",
  "92373",
  "92374",
  "92375",
  "92376",
  "92377",
  "92378",
  "92382",
  "92385",
  "92386",
  "92391",
  "92392",
  "92393",
  "92394",
  "92395",
  "92397",
  "92398",
  "92399",
  "92401",
  "92402",
  "92403",
  "92404",
  "92405",
  "92406",
  "92407",
  "92408",
  "92410",
  "92411",
  "92412",
  "92413",
  "92414",
  "92415",
  "92418",
  "92423",
  "92424",
  "92427",
  "92501",
  "92502",
  "92503",
  "92504",
  "92505",
  "92506",
  "92507",
  "92508",
  "92509",
  "92513",
  "92514",
  "92515",
  "92516",
  "92517",
  "92518",
  "92519",
  "92521",
  "92522",
  "92530",
  "92531",
  "92532",
  "92536",
  "92539",
  "92543",
  "92544",
  "92545",
  "92546",
  "92548",
  "92549",
  "92551",
  "92552",
  "92553",
  "92554",
  "92555",
  "92556",
  "92557",
  "92561",
  "92562",
  "92563",
  "92564",
  "92567",
  "92570",
  "92571",
  "92572",
  "92581",
  "92582",
  "92583",
  "92584",
  "92585",
  "92586",
  "92587",
  "92589",
  "92590",
  "92591",
  "92592",
  "92593",
  "92595",
  "92596",
  "92599",
  "92602",
  "92603",
  "92604",
  "92605",
  "92606",
  "92607",
  "92609",
  "92610",
  "92612",
  "92614",
  "92615",
  "92616",
  "92617",
  "92618",
  "92619",
  "92620",
  "92623",
  "92624",
  "92625",
  "92626",
  "92627",
  "92628",
  "92629",
  "92630",
  "92637",
  "92646",
  "92647",
  "92648",
  "92649",
  "92650",
  "92651",
  "92652",
  "92653",
  "92654",
  "92655",
  "92656",
  "92657",
  "92658",
  "92659",
  "92660",
  "92661",
  "92662",
  "92663",
  "92672",
  "92673",
  "92674",
  "92675",
  "92676",
  "92677",
  "92678",
  "92679",
  "92683",
  "92684",
  "92685",
  "92688",
  "92690",
  "92691",
  "92692",
  "92693",
  "92694",
  "92697",
  "92698",
  "92701",
  "92702",
  "92703",
  "92704",
  "92705",
  "92706",
  "92707",
  "92708",
  "92709",
  "92710",
  "92711",
  "92712",
  "92725",
  "92728",
  "92735",
  "92780",
  "92781",
  "92782",
  "92799",
  "92801",
  "92802",
  "92803",
  "92804",
  "92805",
  "92806",
  "92807",
  "92808",
  "92809",
  "92811",
  "92812",
  "92814",
  "92815",
  "92816",
  "92817",
  "92821",
  "92822",
  "92823",
  "92825",
  "92831",
  "92832",
  "92833",
  "92834",
  "92835",
  "92837",
  "92838",
  "92840",
  "92841",
  "92842",
  "92843",
  "92844",
  "92845",
  "92846",
  "92850",
  "92856",
  "92857",
  "92859",
  "92860",
  "92861",
  "92862",
  "92863",
  "92864",
  "92865",
  "92866",
  "92867",
  "92868",
  "92869",
  "92870",
  "92871",
  "92877",
  "92878",
  "92879",
  "92880",
  "92881",
  "92882",
  "92883",
  "92885",
  "92886",
  "92887",
  "92899",
  "93001",
  "93002",
  "93003",
  "93004",
  "93005",
  "93006",
  "93007",
  "93009",
  "93010",
  "93011",
  "93012",
  "93013",
  "93014",
  "93015",
  "93016",
  "93020",
  "93021",
  "93022",
  "93023",
  "93024",
  "93030",
  "93031",
  "93032",
  "93033",
  "93034",
  "93035",
  "93036",
  "93040",
  "93041",
  "93042",
  "93043",
  "93044",
  "93060",
  "93061",
  "93062",
  "93063",
  "93064",
  "93065",
  "93066",
  "93067",
  "93093",
  "93094",
  "93099",
  "93101",
  "93102",
  "93103",
  "93105",
  "93106",
  "93107",
  "93108",
  "93109",
  "93110",
  "93111",
  "93116",
  "93117",
  "93118",
  "93120",
  "93121",
  "93130",
  "93140",
  "93150",
  "93160",
  "93190",
  "93199",
  "93203",
  "93205",
  "93206",
  "93215",
  "93216",
  "93220",
  "93222",
  "93224",
  "93225",
  "93226",
  "93238",
  "93240",
  "93241",
  "93243",
  "93249",
  "93250",
  "93251",
  "93252",
  "93254",
  "93255",
  "93263",
  "93268",
  "93276",
  "93280",
  "93283",
  "93285",
  "93287",
  "93301",
  "93302",
  "93303",
  "93304",
  "93305",
  "93306",
  "93307",
  "93308",
  "93309",
  "93311",
  "93312",
  "93313",
  "93314",
  "93380",
  "93381",
  "93382",
  "93383",
  "93384",
  "93385",
  "93386",
  "93387",
  "93388",
  "93389",
  "93390",
  "93401",
  "93402",
  "93403",
  "93405",
  "93406",
  "93407",
  "93408",
  "93409",
  "93410",
  "93412",
  "93420",
  "93421",
  "93422",
  "93423",
  "93424",
  "93427",
  "93428",
  "93429",
  "93430",
  "93432",
  "93433",
  "93434",
  "93435",
  "93436",
  "93437",
  "93438",
  "93440",
  "93441",
  "93442",
  "93443",
  "93444",
  "93445",
  "93446",
  "93447",
  "93448",
  "93449",
  "93451",
  "93452",
  "93453",
  "93454",
  "93455",
  "93456",
  "93457",
  "93458",
  "93460",
  "93461",
  "93463",
  "93464",
  "93465",
  "93475",
  "93483",
  "93501",
  "93502",
  "93504",
  "93505",
  "93510",
  "93516",
  "93518",
  "93519",
  "93523",
  "93524",
  "93527",
  "93528",
  "93531",
  "93532",
  "93534",
  "93535",
  "93536",
  "93539",
  "93543",
  "93544",
  "93550",
  "93551",
  "93552",
  "93553",
  "93554",
  "93555",
  "93556",
  "93558",
  "93560",
  "93561",
  "93562",
  "93563",
  "93581",
  "93584",
  "93586",
  "93590",
  "93591",
  "93592",
  "93596",
  "93599",
]);
